<template>
  <div id="table-deposit" class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <b-row class="search__export align-items-center">
        <b-col cols="10">
          <div class="d-flex pl-2">
            <b-img
              class="filter-image"
              :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
              role="button"
              @click="
                filter.sort_type == 'asc'
                  ? (filter.sort_type = 'desc')
                  : (filter.sort_type = 'asc')
              "
            />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input
                  v-model="filter.search"
                  placeholder="Cari Nama Pemasok"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="2" class="px-0">
          <div>
            <b-button
              class="py-1 border-8 w-100"
              v-b-modal.modal-pengajuan
              @click="cleanUpForm()"
            >
              Tambah Pengajuan
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="isLoading == true"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />

        <span v-else>
          <span>
            {{ props.column.label }}
          </span>
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->

        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="detailItem(props.row.uuid)">
                <span class="pr-5"> Detail Data </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalPengajuan
      :results="resultDetail"
      :create-item="createItem"
      :form-data="formPayload"
      :country-list="countryList"
      :search-country="searchCountry"
      :searchQuery="searchQuery"
      :edit-id="editId"
      :clean-up-form="cleanUpForm"
      :isLoading="isLoading"
      :list-merchant="listMerchant"
      :photoKtp="photoKtp"
      :photoNpwp="photoNpwp"
      :photoShop="photoShop"
      @getPayload="getPayload"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { errorNotification } from "@/auth/utils";
import {
  BPagination,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import ModalPengajuan from "@/components/Purchase/Modal/ModalPengajuan.vue";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    ModalPengajuan,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    return {
      errorNotification,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      editId: null,
      isLoading: false,
      columns: [
        {
          label: "Pemasok",
          field: "target_merchant",
        },
        {
          label: "Metode Bayar",
          field: "payment_type",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Limit",
          field: "limit",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
      resultDetail: {},
      photoKtp: null,
      photoNpwp: null,
      photoShop: null,
      formPayload: {
        target_merchant_id: null,
        store_name: "",
        owner_name: "",
        phone_number: "",
        phone_number_country: "",
        address: "",
        ktp_photo: "",
        npwp_photo: "",
        shop_photo: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        payment_type: null,
        limit: null,
      },
      listMerchant: [],
      countryList: [],
      messages: {},
      searchQuery: "",
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
    this.getDataMerchant();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.photoKtp = "";
      this.photoNpwp = "";
      this.photoShop = "";
      this.formPayload = {
        target_merchant_id: null,
        store_name: "",
        owner_name: "",
        phone_number: "",
        phone_number_country: "",
        address: "",
        ktp_photo: "",
        npwp_photo: "",
        shop_photo: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        payment_type: null,
        limit: null,
      };
      this.validations = "";
    },
    getPayload(value) {
      this.formPayload = value;
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          const uniqueCountries = response.data.data.data.filter((country, index, self) =>
              index === self.findIndex((c) => c.country_code === country.country_code)
          );
          this.countryList = uniqueCountries;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detailItem(id) {
      this.cleanUpForm();
      this.editId = id;
      this.messages = {};
      this.$store
        .dispatch("pengajuan/getDetail", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.photoKtp = result.data.data.ktp_photo;
          this.photoNpwp = result.data.data.npwp_photo;
          this.photoShop = result.data.data.shop_photo;
          this.$store.commit(
            "pengajuan/setFormPayload",
            (this.formPayload = {
              target_merchant: result.data.data.target_merchant,
              store_name: result.data.data.store_name,
              owner_name: result.data.data.owner_name,
              phone_number: result.data.data.phone_number,
              address: result.data.data.address,
              payment_type: result.data.data.payment_type,
              limit: result.data.data.limit,
              selectedCountry: result.data.data.phone_number_country,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });

      this.$bvModal.show("modal-pengajuan");
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_number_country =
            this.formPayload.selectedCountry.country_code)
        : "";
      const { ...formPayloadWithoutPettyCashSeperate } = this.formPayload;

      const form = new FormData();

      for (const key in formPayloadWithoutPettyCashSeperate) {
        if (formPayloadWithoutPettyCashSeperate.hasOwnProperty(key)) {
          if (key != "selectedCountry") {
            form.append(key, formPayloadWithoutPettyCashSeperate[key]);
          }
        }
      }
      return form;
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin melakukan Pengajuan ke Pemasok?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const form = this.preparePayload();
          this.isLoading = true;
          this.$store
            .dispatch("pengajuan/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              this.getData();
              this.$bvModal.hide("modal-pengajuan");
              successNotification(
                this,
                "Success",
                "Berhasil Mengajukan ke Pemasok"
              );
              this.cleanUpForm();
              this.isLoading = false;
            })
            .catch((error) => {
              this.messages = error.response.data.meta.messages;
              this.isLoading = false;
            });
        }
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("pengajuan/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.rows = result.data.data;
          this.getCountry();
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.log(err);
        });
    },
    getDataMerchant() {
      this.$store
        .dispatch("pengajuan/getDataMerchant")
        .then((result) => {
          this.listMerchant = result.data.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

#table-deposit {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
